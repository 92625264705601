import React, { useState } from "react";

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "¿Qué hace ICOV?",
      answer:
        "En ICOV nos dedicamos al desarrollo de tecnologías innovadoras, con especialidad en el aprendizaje de máquina y aprendizaje profundo. Desarrollamos crecimiento para empresas de retail, minería, eléctrica, logística. Buscamos transmitir el mensaje de cuidado al medio ambiente, entregando productos que promueven la sostenibilidad y sustentabilidad con nuestro planeta.",
    },
    {
      question: "¿Qué servicios ofrece ICOV?",
      answer:
        "Ofrecemos servicio de captura y análisis de datos en diferentes áreas, que ayuden a nuestros clientes a tomar decisiones informadas y crear estrategias de negocio eficaces.",
    },
    {
      question: "¿Para Qué ocupan inteligencia artificial en ICOV?",
      answer:
        "ICOV utiliza la inteligencia artificial (IA) para diversas tareas, como la identificación de patrones en grandes conjuntos de datos, la predicción de comportamientos o resultados futuros, la optimización de procesos y la automatización de tareas repetitivas. Además, la IA también puede ser utilizada para mejorar la precisión y velocidad de los análisis y la toma de decisiones, lo que puede llevar a mejores resultados y ventajas competitivas para la empresa.",
    },
    {
      question: "¿Cuáles son sus clientes?",
      answer:
        "Trabajamos de la mano con empresas del área eléctrica, logística, minera y retail.  Para contratar cualquier servicio personalizado, es necesario contactarse directamente con nuestro equipo. Puedes concretar una reunión en los siguientes números +56 9 57541875 +56 2 24760964 o enviar un correo a contacto@icov.cl.",
    },
    {
      question: "¿Cómo ocupan los datos de una empresa?",
      answer:
        "En ICOV, utilizamos los datos como una herramienta fundamental para crear valor para nuestros clientes y ayudarlos a mejorar su eficiencia, eficacia y rentabilidad. Recopilamos, almacenamos y analizamos datos de diversas fuentes, como sistemas de información de la empresa, redes sociales, datos de mercado, datos financieros y datos de clientes, entre otros. Entre las posibles aplicaciones de los datos en nuestros servicios se encuentran el análisis de tendencias y patrones de comportamiento de los clientes, la identificación de oportunidades de mercado, la optimización de procesos internos, la predicción de resultados futuros, y la automatización de tareas repetitivas, entre otras.",
    },
    {
      question: "¿Su atención es personalizada?",
      answer:
        "Si, la atención personalizada es esencial para garantizar que los clientes reciban los servicios que mejor se adapten a sus necesidades y objetivos. Para ofrecer una atención personalizada, la empresa puede asignar a cada cliente un gerente de proyecto o un equipo de trabajo dedicado a atender sus necesidades. Este equipo puede trabajar en estrecha colaboración con el cliente para entender sus objetivos, requerimientos y limitaciones, y proponer soluciones personalizadas y adaptadas a sus necesidades específicas. Primero, se identifican las fuentes de datos relevantes para el análisis estratégico. Estas fuentes pueden incluir bases de datos internas, como registros de ventas, inventarios, facturación, datos de clientes y datos financieros, entre otros. También se pueden utilizar fuentes de datos externas, como estudios de mercado, información de proveedores, datos económicos y financieros, y datos de redes sociales. Una vez identificadas las fuentes de datos relevantes, se procede a capturar los datos mediante diferentes técnicas, como la recopilación manual de datos, la automatización de procesos de recolección de datos mediante herramientas de scraping, y la recopilación de datos en tiempo real a través de sensores y dispositivos conectados a internet de las cosas (IoT). Posteriormente, se procede a la limpieza y transformación de los datos, eliminando los datos irrelevantes, duplicados o incompletos y homogeneizando los formatos y valores para su posterior análisis. Finalmente, se utiliza herramientas y técnicas de análisis de datos para extraer información útil de los datos, identificar patrones y tendencias, y presentar los resultados de manera clara y útil para la toma de decisiones.",
    }
  ];

  return (
    <div className="faqContainer" id="FAQ">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">Preguntas Frecuentes</h2>
      <div>
        {faqs.map((faq, index) => (
          <div key={index} style={{ borderBottom: "1px solid #ddd", padding: "5px 0" }}>

            {/* Pregunta y flecha */}
            <div className="faqPreguntaFlecha" style={{ backgroundColor: activeIndex === index ? "#131a29" : "#fff", transition: "background-color 0.3s ease" }} onClick={() => toggleAnswer(index)}>
              <h3 style={{ color: activeIndex === index ? "#fff" : "#333" }}>
                {faq.question}
              </h3>

              <span style={{ color: activeIndex === index ? "#fff" : "#424242", fontSize: "20px", marginLeft: "10px", transition: "transform 0.3s", transform: activeIndex === index ? "rotate(180deg)" : "rotate(0deg)" }}>
                ▼
              </span>
            </div>

            {/* Respuesta */}
            {activeIndex === index && (
              <p style={{ marginTop: "10px", fontSize: "16px", color: "#666", padding: "0 5px" }}>
                {faq.answer}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
